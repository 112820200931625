/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";

const Terms: FC = () => {
  return (
    <div className="modal fade" id="terms" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Terms of Use</h5>

            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span className="svg-icon svg-icon-2x">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <p>
              <b>Conditions of use</b>
              <br />
              By using this website, you certify that you have read and reviewed
              this Agreement and that you agree to comply with its terms. If you
              do not want to be bound by the terms of this Agreement, you are
              advised to leave the website accordingly. OxaPay only grants use
              and access of this website, its products, and its services to
              those who have accepted its terms.
              <br />
              <br />
              <b>Age restriction</b>
              <br />
              You must be at least 16 (sixteen) years of age before you can use
              this website. By using this website, you warrant that you are at
              least 16 years of age and you may legally adhere to this
              Agreement. OxaPay assumes no responsibility for liabilities
              related to age misrepresentation.
              <br />
              <br />
              <b>Intellectual property</b>
              <br />
              You agree that all materials, products, and services provided on
              this website are the property of OxaPay, its affiliates,
              directors, officers, employees, agents, suppliers, or licensors
              including all copyrights, trade secrets, trademarks, patents, and
              other intellectual property. You also agree that you will not
              reproduce or redistribute the OxaPay’s intellectual property in
              any way, including electronic, digital, or new trademark
              registrations. You grant OxaPay a royalty-free and non-exclusive
              license to display, use, copy, transmit, and broadcast the content
              you upload and publish. For issues regarding intellectual property
              claims, you should contact the company in order to come to an
              agreement.
              <br />
              <br />
              <b>User accounts</b>
              <br />
              As a user of this website, you may be asked to register with us
              and provide private information. You are responsible for ensuring
              the accuracy of this information, and you are responsible for
              maintaining the safety and security of your identifying
              information. You are also responsible for all activities that
              occur under your account or password. If you think there are any
              possible issues regarding the security of your account on the
              website, inform us immediately so we may address it accordingly.
              We reserve all rights to terminate accounts, edit or remove
              content and cancel orders in their sole discretion.
              <br />
              <br />
              <b>Applicable law</b>
              <br />
              By visiting this website, you agree that the laws of OxaPay,
              without regard to principles of conflict laws, will govern these
              terms and conditions, or any dispute of any sort that might come
              between OxaPay and you, or its business partners and associates.
              <br />
              <br />
              <b>Indemnification</b>
              <br />
              You agree to indemnify OxaPay and its affiliates and hold OxaPay
              harmless against legal claims and demands that may arise from your
              use or misuse of our services. We reserve the right to select our
              own legal counsel.
              <br />
              <br />
              <b>Limitation on liability</b>
              <br />
              OxaPay is not liable for any damages that may occur to you as a
              result of your misuse of our website. OxaPay reserves the right to
              edit, modify, and change this Agreement any time. This Agreement
              is an understanding between OxaPay and the user, and this
              supersedes and replaces all prior agreements regarding the use of
              this website.
              <br />
              <br />
              <b>Privacy Policy</b>
              <br />
              At OxaPay, we value your privacy and are committed to protecting
              your personal information. This Privacy Policy explains how we
              collect, use, and disclose your personal information when you use
              our website and services.
              <br />
              <br />
              <b>Information We Collect</b>
              <br />
              We may collect personal information from you when you use our
              website or services, such as your name, email address, and payment
              information. We may also collect non-personal information, such as
              your device type and usage data.
              <br />
              <br />
              <b>How We Use Your Information</b>
              <br />
              We use your personal information to provide and improve our
              services, communicate with you, and process payments. We may also
              use your information to send you marketing communications and
              conduct research and analytics.
              <br />
              <br />
              <b>Security</b>
              <br />
              We take reasonable measures to protect your personal information
              from unauthorized access, disclosure, or destruction. However,
              please be aware that no method of transmission over the internet
              or electronic storage is 100% secure.
              <br />
              <br />
              <b>Your Choices</b>
              <br />
              You can opt-out of receiving marketing communications from us at
              any time by following the instructions in the communication. You
              may also request access to, correction, or deletion of your
              personal information.
              <br />
              <br />
              <b>Account Closure & Suspension</b>
              <br />
              1. You have the freedom to close your Account at any time at your
              own discretion.
              <br />
              2. Prior to closing your Account, it is necessary to withdraw all
              your assets to external wallets.
              <br />
              3. The Company reserves the right to suspend your Account in the
              following circumstances:
              <br />- If we have a reasonable belief that your Account has been
              compromised, or for other security-related reasons.
              <br />- If we have a reasonable belief that your Account is being
              accessed by an unauthorized person.
              <br />- If you fail to comply with these Terms, as well as any
              other documents that are part of these Terms.
              <br />- If you have provided us with false, incomplete,
              misleading, or inaccurate information.
              <br />- If you, your Account, or your behavior pose regulatory
              risks to the Company. - If we reasonably suspect you of engaging
              in fraudulent activities or violating regulatory laws.
              <br />- If there are other reasons that constitute a violation of
              these Terms and the regulatory laws.
              <br />
              4. The Company may, at its discretion, choose to inform you about
              the reasons for suspending your Account. During the suspension
              period, assets in your Account may be temporarily blocked and
              unavailable for withdrawal.
              <br />
              5. In the event of Account closure or suspension, you will still
              be responsible for all applicable fees.
              <br />
              6. The Company cannot be held responsible for any losses incurred
              as a result of the closure or suspension of your Account.
              <br />
              <br />
              <b>Our services</b>
              <br />
              By accessing or utilizing the Services, you ("User," "you," or
              "your") hereby consent to be bound by this Agreement, along with
              our updated Privacy Policy, which is included herein by reference.
              Should you disagree with these terms, kindly refrain from
              accessing or using the Services.
              <br />
              <br />
              <b>OUR SERVICES:</b>
              <br />
              1. Through our Services, we offer a convenient and straightforward
              solution for merchants who wish to receive payment for their
              products or services in cryptocurrency. These individuals,
              referred to as "Merchants," can benefit from a user-friendly
              platform that enables them to easily receive payments in
              cryptocurrency. Simultaneously, we provide a seamless payment
              experience for individuals interested in purchasing the products
              or services offered by these merchants. These individuals,
              referred to as “shopper,” can conveniently pay for their desired
              items or services using cryptocurrency. <br />
              <br />
              2. OxaPay does not operate as a bank or financial institution, and
              we do not provide investment advice or financial consulting
              services to our users. Our primary role is solely to offer our
              services as a provider. It is important to note that any
              communication or information provided by OxaPay to users should
              not be construed as advice.
              <br />
              <br />
              3. You ackowledge that participating in activities involving
              cryptocurrency entails inherent risks, and utilizing our Services
              exposes you to potential financial risks. <br />
              <br />
              4. The Services are available 24/7. However, OxaPay reserves the
              right to temporarily suspend the Services, without prior notice or
              compensation, in order to perform necessary maintenance tasks,
              updates, server amendments, and other related activities. You
              understand and acknowledge that occasional delays in the provision
              of Services may occur. <br />
              <br />
              5. It is your responsibility to ensure that you have all the
              necessary equipment and software required to access and utilize
              the Services. Any fees associated with your internet connection,
              mobile usage, or other related charges incurred while accessing or
              using the Services are solely your responsibility. OxaPay does not
              guarantee or warrant that the Services will be fully compatible or
              interoperable with your specific equipment and software. It is
              your responsibility to ensure that your systems meet the necessary
              requirements for accessing and using the Services. <br />
              <br />
              6. Ensuring the security of your devices while using OxaPay
              Services is your responsibility. It is important that you take
              appropriate measures to safeguard your devices, including keeping
              your anti-virus software up to date and implementing necessary
              precautions to protect against malware and other security threats.
              OxaPay cannot be held accountable for any loss or damages that may
              occur as a result of your failure to maintain the security of your
              devices or keep them free from malware. It is crucial to take
              proactive steps to protect your devices to mitigate potential
              risks.
              <br />
              <br />
              7. OxaPay explicitly states that we have no control over, nor
              liability for, the delivery, quality, safety, legality, or any
              other aspects of the goods or services offered by merchants or
              purchased by clients using our Service. It is the responsibility
              of the parties involved to ensure the completion of transactions,
              including any issues related to the amount paid. Should any
              problems arise with the products, goods, services, or the paid
              amount as a client using our Service, or if disputes occur between
              merchants and their clients, it is advised to resolve the matter
              directly with the merchant without involving OxaPay. Merchants are
              solely responsible for resolving issues related to the payments
              they receive. We do not accept or handle any queries, letters,
              requests, or complaints regarding the quality, legality, safety,
              delivery, payments, or any other aspects of the products, goods,
              or services purchased through the Service.
              <br />
              <br />
              When you access or use the Services, it is important to recognize
              and assume full responsibility for your conduct and behavior. You
              acknowledge that any outcomes or repercussions arising from your
              actions are your sole responsibility.
              <br />
              <br />
              -You acknowledge and accept full responsibility for your actions
              when accessing or utilizing the Services, and you understand that
              you will be held accountable for any outcomes that may arise as a
              result. You agree to utilize the Services exclusively for lawful,
              appropriate, and in accordance with these Terms, as well as all
              relevant laws and regulations. To provide clarity and without
              limitation, you are prohibited from engaging in the following
              activities, either personally or by permitting any third party to
              do so:
              <br />
              <br />
              -refrain from disseminating viruses, worms, defects, Trojan
              horses, corrupted files, hoaxes, or any other content that is
              destructive or deceptive in nature;
              <br />
              <br />
              -The access to the Website, the Account, and/or specific Services,
              may be disabled by the Company at any given time, at its sole
              discretion. The Company's Services solely pertain to Crypto
              Currencies supported by the Platform.
              <br />
              <br />
              -To stay updated on the list of supported Crypto Currencies, it is
              advised to frequently visit the Website. It is important to
              refrain from depositing any Crypto Currencies that are not
              supported by the Platform using your Account.
              <br />
              <br />
              -Upload, post, transmit, or make available through the Service any
              content that violates the intellectual property rights of any
              party, including but not limited to patents, trademarks,
              copyrights, trade secrets, or any other proprietary rights, unless
              you are the rightful owner of such rights or have obtained
              explicit permission from the owner to share such content.
              <br />
              <br />
              -Engaging in any unlawful businesses or activities using the
              Services is strictly prohibited.
              <br />
              <br />
              -You are prohibited from modifying, adapting, or reverse
              engineering any part of the Website or any software utilized for
              providing the Services.
              <br />
              <br />
              -Do not remove any copyright, trademark, or other proprietary
              rights notices that are present in or on the Website or any
              content posted on it.
              <br />
              <br />
              -Do not employ any robot, spider, site search/retrieval
              application, or other device to collect information about our
              customers for unauthorized purposes.
              <br />
              <br />
              -Do not access or use the Services with the intention of bringing
              an intellectual property infringement claim against OxaPay.
              Additionally, refrain from using the Services to interfere with
              their proper functioning or to create a product or service that
              competes with the Services.
              <br />
              <br />
              -Do not utilize the Services in manners that are not explicitly
              specified in these Terms.
              <br />
              <br />
              By using Services, you acknowledge and declare that your business
              is not registered in Cuba, Iran, North Korea, Crimea, Sudan,
              Syria, United States of America (including all USA territories
              like Puerto Rico, American Samoa, Guam, Northern Mariana Island,
              and the US Virgin Islands (St. Croix, St. John and St. Thomas)),
              Bangladesh, Hong Kong and Bolivia, along with any other country
              subject to United Nations Security Council Sanctions List and its
              equivalent.
              <br />
              <br />
              The services may experience delays attributable to various
              factors, such as technical challenges, legitimate concerns
              regarding the origin or nature of deposited funds on the platform,
              among other considerations.
              <br />
              <br />
              <b>Changes to Our Privacy Policy</b>
              <br />
              We may update this Privacy Policy from time to time. If we make
              material changes, we will notify you by email or by posting a
              notice on our socials.
              <br />
              <br />
              <b>Contact Us</b>
              <br />
              If you have any questions or concerns about our Privacy Policy,
              please contact us at OxaPay Help Center . By signing up for our
              services, you agree to our Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Terms };
