import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../app/modules/auth";
import { useRequest } from "../../app/services/userHttpRequests";
import { WalletWebappSendContext } from "../../_oxapay/layout/core/WalletWebappSendContext";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { WebappInput } from "./WebappInput";
import { errorHandler } from "../../app/services/errorHandler";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";
import { alertsMapSendCheckAddres } from "../../app/services/alertsMap";
import { TwoFactorWarning } from "../modalContents/TwoFactorWarning";
import { WebappSendLock } from "./WebappSendLock";
import { useNavigate, useParams } from "react-router-dom";
import { WalletWebappRecentAddress } from "./WalletWebappRecentAddress";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { WebappAddressbookFormModal } from "./WebappAddressbookFormModal";
import { Collapse } from "react-bootstrap";

interface props {
  onSuccess: () => void;
}

export const WalletWebappSendStepOne = ({ onSuccess }: props) => {
  const [oneModalIsOpen, setOneModalIsOpen] = useState(false);
  const [showAddressbookForm, setShowAddressbookForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const { sendAsset } = useRequest();
  const { currentUser } = useAuth();
  const { setStepTwoOptions } = useContext(WalletWebappSendContext);
  const { showModal, setComponent, setShowModal } =
    useContext(WebappModalContext);
  const { addressbook } = useContext(AppContext);
  const navigate = useNavigate();
  const { symbol } = useParams();

  const formik = useFormik({
    initialValues: {
      address: "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Receiver address is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (currentUser?.twoFa) {
        setLoading(true);
        setSubmitting(true);
        try {
          const res = await sendAsset(values.address);
          if (res.status === 200) {
            const stepTwoOptions = {
              address: res.address,
              coins: res.allowed,
              intenal: res.internal,
            };
            setStepTwoOptions(stepTwoOptions);
            onSuccess();
            resetForm();
          } else {
            setShowModal(true);
            setComponent(
              <WebappFailAlert
                content={`${alertsMapSendCheckAddres[res.errorNum.toString()]}`}
              />
            );
          }
        } catch (error) {
          errorHandler(error as Error);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      } else {
        setComponent(
          <TwoFactorWarning
            message="For enhanced security, it is required to enable Two-Factor
        Authentication to transfer assets."
          />
        );
        setShowModal(true);
      }
    },
  });

  const WebappFn = (window as any)?.Telegram?.WebApp;

  useEffect(() => {
    const backBtnFn = () => {
      symbol
        ? navigate(`/webapp-wallet/coin/${symbol}`)
        : navigate(`/webapp-wallet`);
    };

    WebappFn?.BackButton?.onClick(backBtnFn).show();

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn).hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !oneModalIsOpen &&
      !showAddressbookForm &&
      !showModal &&
      currentUser?.twoFa
    ) {
      if (loading) {
        WebappFn?.MainButton?.show()
          .setText("Please wait")
          .offClick(formik.handleSubmit)
          .showProgress(true);
      } else {
        WebappFn?.MainButton?.show()
          .setText("Next")
          .onClick(formik.handleSubmit)
          .hideProgress();
      }
    }

    return () => {
      WebappFn?.MainButton?.hide().offClick(formik.handleSubmit).hideProgress();
    };
    // eslint-disable-next-line
  }, [loading, showModal, showAddressbookForm, oneModalIsOpen]);

  useEffect(() => {
    if (formik.values.address.length > 10) {
      const foundInAddressbook = addressbook.find(
        (item) => item.address === formik.values.address
      );
      !foundInAddressbook ? setShowAddAddress(true) : setShowAddAddress(false);
    } else {
      setShowAddAddress(false);
    }
  }, [formik.values.address, addressbook]);

  return currentUser?.twoFa ? (
    <div className="d-flex flex-column" style={{ height: "100%" }}>
      <div className="d-flex flex-column pt-15 mb-7">
        <WebappInput
          label="Receiver address"
          qrCode
          name="address"
          autoComplete="off"
          placeholder="Receiver address"
          addressbookWidget
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.address}
          error={formik.errors.address && formik.touched.address ? true : false}
          clear={formik.resetForm}
          onGetData={(value) => formik.setFieldValue("address", value)}
          onChangeModalShow={(value) => setOneModalIsOpen(value)}
        />
        {formik.errors.address && formik.touched.address && (
          <p className="text-danger fw-semibold fs-5 ms-3 mt-1 mb-0">
            {formik.errors.address}
          </p>
        )}

        <Collapse in={showAddAddress}>
          <div className="px-5 mt-2 mb-2">
            <button
              type="button"
              className="btn p-0 text-primary mt-2"
              onClick={() => {
                setShowAddressbookForm(true);
              }}
            >
              Add to Address Book
            </button>
          </div>
        </Collapse>

        <p className="fs-6 lh-normal m-0 px-5 mt-3">
          <span className="bg-primary text-white px-2 rounded fs-7 me-1">
            i
          </span>
          If the Receiver address belongs to OxaPay's users, the transfer fee is
          zero.
        </p>
        <p className="fs-6 lh-normal m-0 px-5 mt-3">
          <span className="bg-primary text-white px-2 rounded fs-7 me-1">
            i
          </span>
          Send assets to smart contract addresses or official telegram wallet
          (t.me/@wallet) may not be processed correctly or may result in your
          funds being lost or inaccessible.
        </p>
      </div>

      <div className="d-flex flex-column flex-grow-1 flex-1 h-100px">
        <WalletWebappRecentAddress
          onChangeModalShow={(value) => setOneModalIsOpen(value)}
          onSelect={(address) => formik.setFieldValue("address", address)}
        />
      </div>

      <WebappAddressbookFormModal
        address={formik.values.address}
        showAddressBookForm={showAddressbookForm}
        onClose={() => setShowAddressbookForm(false)}
      />
    </div>
  ) : (
    <WebappSendLock />
  );
};
