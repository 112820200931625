import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../app/modules/auth";
import { alertsMapSendCheckAddres } from "../app/services/alertsMap";
import { errorHandler } from "../app/services/errorHandler";
import { FailedModalFire } from "../app/services/failedSwalFire";
import { useRequest } from "../app/services/userHttpRequests";
import { KTSVG } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { ModalContext } from "../_oxapay/layout/core/ModalContext";
import { SendContext } from "../_oxapay/layout/core/SendContext";
import { AddressBookFormModal } from "./addressbook/AddressBookFormModal";
import { AddressBookModal } from "./addressbook/AddressBookModal";
import { RecentAddresses } from "./addressbook/RecentAddresses";
import { TwoFactorWarning } from "./modalContents/TwoFactorWarning";
import { WaitButtonSpan } from "./WaitButtonSpan";

interface props {
  onSuccess: () => void;
}

export const SendStepOne = ({ onSuccess }: props) => {
  const [showAddressbookForm, setShowAddressbookForm] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showAddressBook, setShowAddressBook] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sendAsset } = useRequest();
  const { currentUser } = useAuth();
  const { setStepTwoOptions } = useContext(SendContext);
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const { addressbook } = useContext(AppContext);

  const formik = useFormik({
    initialValues: {
      address: "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Receiver address is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (currentUser?.twoFa) {
        setLoading(true);
        setSubmitting(true);
        try {
          const res = await sendAsset(values.address);
          if (res.status === 200) {
            const stepTwoOptions = {
              address: res.address,
              coins: res.allowed,
              intenal: res.internal,
            };
            setStepTwoOptions(stepTwoOptions);
            onSuccess();
            resetForm();
          } else {
            FailedModalFire(alertsMapSendCheckAddres[res.errorNum.toString()]);
          }
        } catch (error) {
          errorHandler(error as Error);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      } else {
        setModalWidth(450);
        setComponent(
          <TwoFactorWarning
            message="For enhanced security, it is required to enable Two-Factor
        Authentication to transfer assets."
          />
        );
        setShowModal(true);
      }
    },
  });

  useEffect(() => {
    if (formik.values.address.length > 10) {
      const foundInAddressbook = addressbook.find(
        (item) => item.address === formik.values.address
      );
      !foundInAddressbook ? setShowAddAddress(true) : setShowAddAddress(false);
    } else {
      setShowAddAddress(false);
    }
  }, [formik.values.address, addressbook]);
  return currentUser?.twoFa ? (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-100 mb-5">
          <div className="d-flex justify-content-between">
            <h4 className="fs-5 fw-semibold text-gray-800">Receiver address</h4>
            <span
              id="checktool"
              className="text-primary cursor-pointer"
              onClick={() => setShowAddressBook(true)}
            >
              <KTSVG
                path="media/icons/duotune/communication/com005.svg"
                className="svg-icon-primary svg-icon-2"
              />{" "}
              Address Book
            </span>
          </div>

          <input
            type="text"
            className={`form-control form-control-solid me-3 flex-grow-1 ${
              formik.errors.address &&
              formik.touched.address &&
              "is-invalid border border-danger"
            }`}
            name="address"
            autoComplete="off"
            placeholder="Receiver address"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address}
          />
          {formik.errors.address && formik.touched.address && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.address}
            </p>
          )}
          {showAddAddress && (
            <button
              type="button"
              className="btn p-0 text-primary mt-2"
              onClick={() => {
                setShowAddressbookForm(true);
              }}
            >
              Add to Address Book
            </button>
          )}
          <p className="text-muted mt-3">
            <span className="badge badge-circle badge-sm badge-outline badge-primary me-1">
              i
            </span>
            If the Receiver address belongs to OxaPay's users, the transfer fee
            is zero.
          </p>

          <p className="text-muted mt-3">
            <span className="badge badge-circle badge-sm badge-outline badge-primary me-1">
              i
            </span>
            Send assets to smart contract addresses or official telegram wallet
            (t.me/@wallet) may not be processed correctly or may result in your
            funds being lost or inaccessible.
          </p>
        </div>
        <RecentAddresses
          onSelect={(address) => formik.setFieldValue("address", address)}
        />
        <div className="d-flex align-items-end">
          <button
            type="submit"
            className="btn btn-primary fs-3 w-100"
            disabled={formik.isSubmitting}
          >
            {loading ? <WaitButtonSpan /> : "Next"}
          </button>
        </div>
      </form>

      <AddressBookModal
        showAddressBook={showAddressBook}
        onClose={(value) => {
          value && formik.setFieldValue("address", value);
          setShowAddressBook(false);
        }}
      />

      <AddressBookFormModal
        address={formik.values.address}
        showAddressBookForm={showAddressbookForm}
        onClose={() => setShowAddressbookForm(false)}
      />
    </>
  ) : (
    <TwoFactorWarning
      className="px-0 py-5"
      message="For enhanced security, it is required to enable Two-Factor
Authentication to transfer assets."
    />
  );
};
